import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import FirmaDigital from '../components/pages/FirmaDigitalConfirm'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const FirmaDigitalTemplate = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      companyName={pageContext.company}
      pageContext={pageContext}
      location={location}
      currentStep={pageContext.step}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <FirmaDigital pageContext={pageContext} companyName={pageContext.company} />
        </>
      )}
    />
  </GlobalFooterProvider>
)

export default FirmaDigitalTemplate
